/**
 * Hardware Buildup
 * Hardware Buildup
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 1 = Time, 2 = Event
 */
export enum ScheduleTriggerTypes {
    Time = 'time',
    Event = 'event'
}

